import { useEffect, useState } from "react";
import useSWR from "swr";
/* import { Form } from "react-bootstrap";*/
import DatePicker from "react-datepicker";
import {
  entities as getEntities,
  metadata as getMetadata,
  languages as getLanguages,
  search,
} from "../api/api";
import { ContactTable } from "../components/ContactTable";
import { useDangerAlert } from "../hooks/useAlert";
import { MultiSelect } from "../components/MultiSelect";
import { Select } from "../components/Select";
import { ContentLayout } from "@cloudscape-design/components";
import { Button, Link, Header, Form, Grid, Container, SpaceBetween, Input, FormField, TextContent } from '@cloudscape-design/components';


function Search({ setAlert }) {
  const [editing, setEditing] = useState(true);
  const [query, setQuery] = useState({});
  const [shouldSearch, setShouldSearch] = useState(true);

  useEffect(() => {
    (query.timestampTo && query.timestampTo) ||
    (!query.timestampTo && !query.timestampFrom)
      ? setShouldSearch(true)
      : setShouldSearch(false);
  }, [query.timestampTo, query.timestampFrom]);
  
  const { data: jobNames, error: errorJobNames } = useSWR(
    `jobName`,
    getMetadata
  );

  const { data: agents, error: errorAgents } = useSWR(
    `agent`,
    getMetadata
  );

  const { data: customers, error: errorCustomers } = useSWR(
    `customer`,
    getMetadata
  );
    
  const { data: queues, error: errorQueues } = useSWR(  
    `queue`,
    getMetadata
  );
  
  const { data: results, error: errorResults } = useSWR(
    shouldSearch ? [`/search`, query] : null,
    () => search(query)
  );

  const handleDates = (dates) => {
    const [start, end] = dates;

    const timestampFrom = new Date(start).getTime();
    const timestampTo = end ? new Date(end).setUTCHours(23, 59, 59, 999) : null;

    handleQueryInput(timestampFrom, "timestampFrom");
    handleQueryInput(timestampTo, "timestampTo");
  };

  const filterEmptyKeys = (obj) => {
    const shouldKeep = (v) => (Array.isArray(v) ? v.length > 0 : v !== null);

    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => shouldKeep(v))
    );
  };

  const handleQueryInput = (input, field) =>
    setQuery((q) => filterEmptyKeys({ ...q, [field]: input }));

  const onClick = () => {
    setEditing(false);
  };

  useDangerAlert(errorJobNames || errorAgents || errorCustomers || errorQueues || errorResults, setAlert);

  return (
  <ContentLayout 
    header={
      <Header
        variant="h1"
        info={<Link variant="info" ariaLabel="Info goes here.">Info</Link>}>
          Search
      </Header>
    }>
      <Container>
        <Form>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Date Range">
              <SpaceBetween direction="horizontal" size="l">
                <DatePicker
                  selectsRange
                  startDate={query.timestampFrom}
                  endDate={query.timestampTo}
                  dateFormat="yyyy-MM-dd"
                  onChange={handleDates}
                  maxDate={new Date()}
                  placeholderText="Select a start and end date"
                />
                <Button
                  className="mt-2"
                  variant="outline-secondary"
                  onClick={() => {
                    handleQueryInput(null, "timestampTo");
                    handleQueryInput(null, "timestampFrom");
                  }}
                >
                  Clear
                </Button>
              </SpaceBetween>
          </FormField>
          <FormField label="JobNames">
            <SpaceBetween direction="horizontal" size="l">
              <MultiSelect
                options={(jobNames || []).map((jobName) => ({
                  value: jobName,
                  label: jobName,
                }))}
                onChange={(value) => handleQueryInput(value, "jobName")}
                isLoading={!jobNames && !errorJobNames}
                />
            </SpaceBetween>
          </FormField>
          <FormField label="Agents">
            <SpaceBetween direction="horizontal" size="l">
              <MultiSelect
                options={(agents || []).map((agent) => ({
                  value: agent,
                  label: agent,
                }))}
                onChange={(value) => handleQueryInput(value, "agent")}
                isLoading={!agents && !errorAgents}
                />
            </SpaceBetween>          
            </FormField>
          <FormField label="Customers">
            <SpaceBetween direction="horizontal" size="l">
              <MultiSelect
                options={(customers || []).map((customer) => ({
                  value: customer,
                  label: customer,
                }))}
                onChange={(value) => handleQueryInput(value, "customer")}
                isLoading={!customers && !errorCustomers}
                />
            </SpaceBetween>
          </FormField>
          <FormField label="Queues">
            <SpaceBetween direction="horizontal" size="l">
              <MultiSelect
                options={(queues || []).map((queue) => ({
                  value: queue,
                  label: queue,
                }))}
                onChange={(value) => handleQueryInput(value, "queue")}
                isLoading={!queues && !errorQueues}
                />
            </SpaceBetween>
          </FormField>
            
          <Button bg={"primary"} onClick={onClick}>
            Search
          </Button>
            
          <hr/>
        </SpaceBetween>
      </Form>
      {!editing && (
          <ContactTable
            header={
              <Header>
              Search Results
              </Header>
            }
    
            variant="embedded"
          data={results}
          loading={!results && !errorResults}
          empty={<NoMatches />}
        />
      )}
      </Container>
    </ContentLayout>
  );
}
const NoMatches = () => (
  <div>
    <h2>No Matches</h2>
    <p>Please try a different query</p>
  </div>
);

export default Search;
